import React, { useEffect, useState } from "react";

const AuthActionPage = ({ path }) => {
  const [verifiedEmail, setVerifiedEmail] = useState(null);
  const [urlParams, setUrlParams] = useState({});
  const [inputNewPassword, setInputNewPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramMode = queryParams.get("mode");
    const paramOobCode = queryParams.get("oobCode");
    const paramApiKey = queryParams.get("apiKey");
    const paramLang = queryParams.get("lang");

    if (paramApiKey && paramOobCode && !urlParams.apiKey) {
      setUrlParams({
        mode: paramMode,
        oobCode: paramOobCode,
        apiKey: paramApiKey,
        lang: paramLang,
      });
      const body = {
        oobCode: paramOobCode,
      };
      fetch(`https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${paramApiKey}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json()) // parse JSON from request
        .then((resultData) => {
          setVerifiedEmail(resultData?.email);
        })
        .catch((err) => {
          //
        });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { mode, oobCode, apiKey, lang } = urlParams;
    if (inputNewPassword && !isSaving) {
      const body = {
        oobCode,
        newPassword: inputNewPassword,
      };
      setIsSaving(true);
      fetch(`https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${apiKey}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json()) // parse JSON from request
        .then((resultData) => {
          const errorMessage = resultData?.error?.message;
          if (errorMessage) {
            alert(errorMessage);
            setIsSaving(false);
          } else {
            setIsResetSuccess(true);
          }
        })
        .catch((err) => {
          setIsSaving(false);
        });
    }
  };

  return (
    <div
      style={{
        margin: 50,
      }}
    >
      {verifiedEmail ? (
        <div>
          {(() => {
            if (isResetSuccess) {
              return (
                <h2>
                  New password is successfully set for {verifiedEmail}. Please login to Tobo App.
                </h2>
              );
            }

            return (
              <div>
                <h2
                  style={{
                    marginBottom: 20,
                  }}
                >
                  Set a new password for {verifiedEmail}
                </h2>
                <form onSubmit={handleSubmit}>
                  <label>
                    New Password:
                    <input
                      type="password"
                      value={inputNewPassword}
                      required
                      style={{ margin: "0 10px" }}
                      onChange={(e) => {
                        setInputNewPassword(e.target.value);
                      }}
                    />
                  </label>
                  <input type="submit" value="Submit" />
                </form>
              </div>
            );
          })()}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default AuthActionPage;
